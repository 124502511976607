/**
 * List of all the networks supported by the Xchange Interface
 */
import { Implementation } from '@uniswap/router-sdk'

import { V2_FACTORY_ADDRESSES, V2_ROUTER_ADDRESS } from '../constants/addresses'
import { SupportedChainId } from '../constants/chains'
import { V2_XCHANGE_FACTORY_ADDRESSES, XCHANGE_ROUTER_V1 } from '../constants/x7'
import { constructSameAddressMap } from '../utils/constructSameAddressMap'

export const dexIdToName: { [key in Implementation]?: string } = {
  [Implementation.UNISWAP]: 'Uniswap',
  [Implementation.XCHANGE]: 'Xchange',
  [Implementation.PANCAKESWAP]: 'PancakeSwap'
}

export const dexIdToPermitHashName: { [key in Implementation]?: string } = {
  [Implementation.UNISWAP]: 'Uniswap V2',
  [Implementation.XCHANGE]: 'Xchange AMM V1',
}

type AddressMap = { [chainId: number]: string }
type InitCodeMap = { [chainId: number]: string }

export const X_V2_FACTORY_ADDRESSES: { [implementation in Implementation]?: AddressMap } = {
  [Implementation.UNISWAP]: V2_FACTORY_ADDRESSES,
  [Implementation.XCHANGE]: V2_XCHANGE_FACTORY_ADDRESSES,
}

export const X_V2_FACTORY_INIT_CODE_HASH: { [implementation in Implementation]?: InitCodeMap } = {
  [Implementation.UNISWAP]: {
    [SupportedChainId.MAINNET]: '0x96e8ac4277198ff8b6f785478aa9a39f403cb768dd02cbee326c3e7da348845f',
  },
  [Implementation.XCHANGE]: {
    [SupportedChainId.MAINNET]: '0x8ef3e731dfb0265c5b89d4d1ef69c1d448b1335eb48d76cb6df26c198f75bc68',
  },
}

export const X_V2_ROUTER_ADDRESSES: { [implementation in Implementation]?: AddressMap } = {
  [Implementation.UNISWAP]: V2_ROUTER_ADDRESS,
  [Implementation.XCHANGE]: XCHANGE_ROUTER_V1,
}

export const V2_FACTORY_ADDRESS: AddressMap = constructSameAddressMap('0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D')
