import { Trans } from '@lingui/macro'
import LoanPositionCard from 'components/LoanCard'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'

import { ButtonPrimary, ButtonSecondary } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import { RowBetween, RowFixed } from '../../components/Row'
import { SwitchLocaleLink } from '../../components/SwitchLocaleLink'
import { ThemedText } from '../../theme'

const PageWrapper = styled(AutoColumn)`
  margin-top: 10px;
  padding: 20px 16px 4px;
  max-width: 540px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.35);

  border-radius: 20px;
  border: 2px solid ${({ theme }) => theme.backgroundOutline};
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    padding: 48px 8px 0px;
  }

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    padding-top: 20px;
  }
`

const TitleRow = styled(RowBetween)`
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    flex-direction: column-reverse;
  `};
`

export const ButtonRow = styled(RowFixed)`
  gap: 8px;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
  `};
`

const ResponsiveButtonPrimary = styled(ButtonPrimary)`
  width: 100%;
  padding: 10px;
  margin: 2px;
  border-radius: 10px;
`

const ResponsiveButtonSecondary = styled(ButtonSecondary)`
  width: 100%;
  border-radius: 12px;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    width: 48%;
  `};
`

const EmptyProposals = styled.div`
  border: 1px solid ${({ theme }) => theme.backgroundOutline};
  padding: 16px 12px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Layer2Prompt = styled(EmptyProposals)`
  margin-top: 16px;
`

export default function LoanIndex() {
  const navigate = useNavigate()
  const { loanId } = useParams<{ loanId?: string }>()
  return (
    <>
      <PageWrapper>
        <AutoColumn gap="lg" justify="center">
          <ThemedText.DeprecatedMediumHeader style={{ marginTop: '0.5rem', justifySelf: 'flex-center' }}>
            <Trans>Initial Liquidity Loans</Trans>
          </ThemedText.DeprecatedMediumHeader>
        </AutoColumn>

        <LoanPositionCard loanId={loanId ? parseInt(loanId) : 1} />
      </PageWrapper>
      <SwitchLocaleLink />
    </>
  )
}
