import { SupportedChainId } from './chains'
import { ContractAddresses, contractKind } from './contracts'

export type ChainContractMap = { [key in SupportedChainId]: ContractAddresses }

export const CHAIN_ADDRESSES: ChainContractMap = {
  [SupportedChainId.MAINNET]: {
    [contractKind.XCHANGE_ROUTER]: '0x7DE8063E9fB43321d2100e8Ddae5167F56A50060',
    [contractKind.XCHANGE_DISCOUNT_ROUTER]: '',
    [contractKind.XCHANGE_FACTORY]: '0x7de800467aFcE442019884f51A4A1B9143a34fAc',
    [contractKind.XCHANGE_DISCOUNT_AUTHORITY]: '0x7De8Ab0dD777561cE98B7Ef413F6fd564E89C1dA',
    [contractKind.XCHANGE_OMNI_ROUTER]: '',
    [contractKind.THIRD_PARTY_V2_ROUTER]: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
    [contractKind.THIRD_PARTY_V2_FACTORY]: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
    [contractKind.LENDING_POOL_RESERVE]: '0x7Ca54e9Aa3128bF15f764fa0f0f93e72b5267000',
    [contractKind.LENDING_POOL_V1]: '0x740015c39da5D148fcA25A467399D00bcE10c001',
    [contractKind.X7_LENDING_DA]: '0x74001e463B3c7dC95D96a1FDBE621678C24D47Da',
    [contractKind.LOAN_TERM_001]: '0x7400165E167479a3c81C8fC8CC3df3D2a92E9017',
    [contractKind.LOAN_TERM_002]: '0x740019A6b3a9cF3bd193986a560B05726143B217',
    [contractKind.LOAN_TERM_003]: '0x74001C747B6cc9091EE63bC9424DfF633FBAc617',
    [contractKind.X7R_TOKEN]: '0x70008F18Fc58928dcE982b0A69C2c21ff80Dca54',
    [contractKind.X7DAO_TOKEN]: '0x7105E64bF67ECA3Ae9b123F0e5Ca2b83b2eF2dA0',
    [contractKind.X7101_TOKEN]: '0x7101a9392EAc53B01e7c07ca3baCa945A56EE105',
    [contractKind.X7102_TOKEN]: '0x7102DC82EF61bfB0410B1b1bF8EA74575bf0A105',
    [contractKind.X7103_TOKEN]: '0x7103eBdbF1f89be2d53EFF9B3CF996C9E775c105',
    [contractKind.X7104_TOKEN]: '0x7104D1f179Cc9cc7fb5c79Be6Da846E3FBC4C105',
    [contractKind.X7105_TOKEN]: '0x7105FAA4a26eD1c67B8B2b41BEc98F06Ee21D105',
    [contractKind.X7100_LIQ_HUB]: '0x7102407afa5d6581AAb694FEB03fEB0e7Cf69ebb',
    [contractKind.X7DAO_LIQ_HUB]: '0x7DA0e45cE7fD8359544Be00a6618215770851ebB',
    [contractKind.X7R_LIQ_HUB]: '0x712E87520f35a0a17A49bcCA4D87c201F0A46EBb',
    [contractKind.X7100_DA]: '0x7100AAcC6047281b105201cb9e0DEcF9Ae5431DA',
    [contractKind.X7DAO_DA]: '0x7da05D75f51056f3B83b43F397668Cf6A5051cDa',
    [contractKind.X7R_DA]: '0x712bC6ddcd97A776B2482531058C629456B93eda',
    [contractKind.X7_TOKEN_TIME_LOCK]: '0x7000F4Cddca46FB77196466C3833Be4E89ab810C',
    [contractKind.X7_TOKEN_BURNER]: '0x70008F0B06060A31515733DB6dCB515c64f3DeAd',
    [contractKind.X7_ECOSYSTEM_SPLITTER]: '0x70001BA1BA4d85739E7B6A7C646B8aba5ed6c888',
    [contractKind.X7_TREASURY_SPLITTER]: '0x70001e625F8C763BE03ffb4135789c7116fef999',
    [contractKind.X7_LIQUIDITY_MAXI_NFT]: '0x7000F8270B955377e047da8202aE3c408186B4F7',
    [contractKind.X7_DEX_MAXI_NFT]: '0x7000b3B5e4e126610A7b7d1Af2D2DE8685c7C4f7',
    [contractKind.X7_BORROWING_MAXI_NFT]: '0x7000D5d7707Bf86b317deC635e459E47b9aBD4F7',
    [contractKind.X7_ECOSYSTEM_MAXI_NFT]: '0x7000CAE2C1016e7De45ec9b54F1835b966BCA4f7',
    [contractKind.MULTICALL]: '0xFC5fE4fd2F234F5F4e65364DBf95B0C80C42Db37',
    [contractKind.USDC]: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    [contractKind.DAI]: '',
    [contractKind.WRAPPED_NATIVE_TOKEN]: '',
  },
  [SupportedChainId.RINKEBY]: {
    [contractKind.XCHANGE_ROUTER]: '0x7DE8063E9fB43321d2100e8Ddae5167F56A50060',
    [contractKind.XCHANGE_DISCOUNT_ROUTER]: '',
    [contractKind.XCHANGE_FACTORY]: '0x7de800467aFcE442019884f51A4A1B9143a34fAc',
    [contractKind.XCHANGE_DISCOUNT_AUTHORITY]: '0x7De8Ab0dD777561cE98B7Ef413F6fd564E89C1dA',
    [contractKind.XCHANGE_OMNI_ROUTER]: '',
    [contractKind.THIRD_PARTY_V2_ROUTER]: '',
    [contractKind.THIRD_PARTY_V2_FACTORY]: '',
    [contractKind.LENDING_POOL_RESERVE]: '0x7Ca54e9Aa3128bF15f764fa0f0f93e72b5267000',
    [contractKind.LENDING_POOL_V1]: '0x740015c39da5D148fcA25A467399D00bcE10c001',
    [contractKind.X7_LENDING_DA]: '0x74001e463B3c7dC95D96a1FDBE621678C24D47Da',
    [contractKind.LOAN_TERM_001]: '0x7400165E167479a3c81C8fC8CC3df3D2a92E9017',
    [contractKind.LOAN_TERM_002]: '0x740019A6b3a9cF3bd193986a560B05726143B217',
    [contractKind.LOAN_TERM_003]: '0x74001C747B6cc9091EE63bC9424DfF633FBAc617',
    [contractKind.X7R_TOKEN]: '0x70008F18Fc58928dcE982b0A69C2c21ff80Dca54',
    [contractKind.X7DAO_TOKEN]: '0x7105E64bF67ECA3Ae9b123F0e5Ca2b83b2eF2dA0',
    [contractKind.X7101_TOKEN]: '0x7101a9392EAc53B01e7c07ca3baCa945A56EE105',
    [contractKind.X7102_TOKEN]: '0x7102DC82EF61bfB0410B1b1bF8EA74575bf0A105',
    [contractKind.X7103_TOKEN]: '0x7103eBdbF1f89be2d53EFF9B3CF996C9E775c105',
    [contractKind.X7104_TOKEN]: '0x7104D1f179Cc9cc7fb5c79Be6Da846E3FBC4C105',
    [contractKind.X7105_TOKEN]: '0x7105FAA4a26eD1c67B8B2b41BEc98F06Ee21D105',
    [contractKind.X7100_LIQ_HUB]: '0x7102407afa5d6581AAb694FEB03fEB0e7Cf69ebb',
    [contractKind.X7DAO_LIQ_HUB]: '0x7DA0e45cE7fD8359544Be00a6618215770851ebB',
    [contractKind.X7R_LIQ_HUB]: '0x712E87520f35a0a17A49bcCA4D87c201F0A46EBb',
    [contractKind.X7100_DA]: '0x7100AAcC6047281b105201cb9e0DEcF9Ae5431DA',
    [contractKind.X7DAO_DA]: '0x7da05D75f51056f3B83b43F397668Cf6A5051cDa',
    [contractKind.X7R_DA]: '0x712bC6ddcd97A776B2482531058C629456B93eda',
    [contractKind.X7_TOKEN_TIME_LOCK]: '0x7000F4Cddca46FB77196466C3833Be4E89ab810C',
    [contractKind.X7_TOKEN_BURNER]: '0x70008F0B06060A31515733DB6dCB515c64f3DeAd',
    [contractKind.X7_ECOSYSTEM_SPLITTER]: '0x70001BA1BA4d85739E7B6A7C646B8aba5ed6c888',
    [contractKind.X7_TREASURY_SPLITTER]: '0x70001e625F8C763BE03ffb4135789c7116fef999',
    [contractKind.X7_LIQUIDITY_MAXI_NFT]: '0x7000F8270B955377e047da8202aE3c408186B4F7',
    [contractKind.X7_DEX_MAXI_NFT]: '0x7000b3B5e4e126610A7b7d1Af2D2DE8685c7C4f7',
    [contractKind.X7_BORROWING_MAXI_NFT]: '0x7000D5d7707Bf86b317deC635e459E47b9aBD4F7',
    [contractKind.X7_ECOSYSTEM_MAXI_NFT]: '0x7000CAE2C1016e7De45ec9b54F1835b966BCA4f7',
    [contractKind.MULTICALL]: '0x1F98415757620B543A52E61c46B32eB19261F984',
    [contractKind.USDC]: '0x4DBCdF9B62e891a7cec5A2568C3F4FAF9E8Abe2b',
    [contractKind.DAI]: '',
    [contractKind.WRAPPED_NATIVE_TOKEN]: '',
  },
  [SupportedChainId.GOERLI]: {
    [contractKind.XCHANGE_ROUTER]: '0x7DE8063E9fB43321d2100e8Ddae5167F56A50060',
    [contractKind.XCHANGE_DISCOUNT_ROUTER]: '',
    [contractKind.XCHANGE_FACTORY]: '0x7de800467aFcE442019884f51A4A1B9143a34fAc',
    [contractKind.XCHANGE_DISCOUNT_AUTHORITY]: '0x7De8Ab0dD777561cE98B7Ef413F6fd564E89C1dA',
    [contractKind.XCHANGE_OMNI_ROUTER]: '',
    [contractKind.THIRD_PARTY_V2_ROUTER]: '',
    [contractKind.THIRD_PARTY_V2_FACTORY]: '',
    [contractKind.LENDING_POOL_RESERVE]: '0x7Ca54e9Aa3128bF15f764fa0f0f93e72b5267000',
    [contractKind.LENDING_POOL_V1]: '0x740015c39da5D148fcA25A467399D00bcE10c001',
    [contractKind.X7_LENDING_DA]: '0x74001e463B3c7dC95D96a1FDBE621678C24D47Da',
    [contractKind.LOAN_TERM_001]: '0x7400165E167479a3c81C8fC8CC3df3D2a92E9017',
    [contractKind.LOAN_TERM_002]: '0x740019A6b3a9cF3bd193986a560B05726143B217',
    [contractKind.LOAN_TERM_003]: '0x74001C747B6cc9091EE63bC9424DfF633FBAc617',
    [contractKind.X7R_TOKEN]: '0x70008F18Fc58928dcE982b0A69C2c21ff80Dca54',
    [contractKind.X7DAO_TOKEN]: '0x7105E64bF67ECA3Ae9b123F0e5Ca2b83b2eF2dA0',
    [contractKind.X7101_TOKEN]: '0x7101a9392EAc53B01e7c07ca3baCa945A56EE105',
    [contractKind.X7102_TOKEN]: '0x7102DC82EF61bfB0410B1b1bF8EA74575bf0A105',
    [contractKind.X7103_TOKEN]: '0x7103eBdbF1f89be2d53EFF9B3CF996C9E775c105',
    [contractKind.X7104_TOKEN]: '0x7104D1f179Cc9cc7fb5c79Be6Da846E3FBC4C105',
    [contractKind.X7105_TOKEN]: '0x7105FAA4a26eD1c67B8B2b41BEc98F06Ee21D105',
    [contractKind.X7100_LIQ_HUB]: '0x7102407afa5d6581AAb694FEB03fEB0e7Cf69ebb',
    [contractKind.X7DAO_LIQ_HUB]: '0x7DA0e45cE7fD8359544Be00a6618215770851ebB',
    [contractKind.X7R_LIQ_HUB]: '0x712E87520f35a0a17A49bcCA4D87c201F0A46EBb',
    [contractKind.X7100_DA]: '0x7100AAcC6047281b105201cb9e0DEcF9Ae5431DA',
    [contractKind.X7DAO_DA]: '0x7da05D75f51056f3B83b43F397668Cf6A5051cDa',
    [contractKind.X7R_DA]: '0x712bC6ddcd97A776B2482531058C629456B93eda',
    [contractKind.X7_TOKEN_TIME_LOCK]: '0x7000F4Cddca46FB77196466C3833Be4E89ab810C',
    [contractKind.X7_TOKEN_BURNER]: '0x70008F0B06060A31515733DB6dCB515c64f3DeAd',
    [contractKind.X7_ECOSYSTEM_SPLITTER]: '0x70001BA1BA4d85739E7B6A7C646B8aba5ed6c888',
    [contractKind.X7_TREASURY_SPLITTER]: '0x70001e625F8C763BE03ffb4135789c7116fef999',
    [contractKind.X7_LIQUIDITY_MAXI_NFT]: '0x7000F8270B955377e047da8202aE3c408186B4F7',
    [contractKind.X7_DEX_MAXI_NFT]: '0x7000b3B5e4e126610A7b7d1Af2D2DE8685c7C4f7',
    [contractKind.X7_BORROWING_MAXI_NFT]: '0x7000D5d7707Bf86b317deC635e459E47b9aBD4F7',
    [contractKind.X7_ECOSYSTEM_MAXI_NFT]: '0x7000CAE2C1016e7De45ec9b54F1835b966BCA4f7',
    [contractKind.MULTICALL]: '0x1F98415757620B543A52E61c46B32eB19261F984',
    [contractKind.USDC]: '0x07865c6e87b9f70255377e024ace6630c1eaa37f',
    [contractKind.DAI]: '',
    [contractKind.WRAPPED_NATIVE_TOKEN]: '',
  },
  [SupportedChainId.KOVAN]: {
    [contractKind.XCHANGE_ROUTER]: '0x7DE8063E9fB43321d2100e8Ddae5167F56A50060',
    [contractKind.XCHANGE_DISCOUNT_ROUTER]: '',
    [contractKind.XCHANGE_FACTORY]: '0x7de800467aFcE442019884f51A4A1B9143a34fAc',
    [contractKind.XCHANGE_DISCOUNT_AUTHORITY]: '0x7De8Ab0dD777561cE98B7Ef413F6fd564E89C1dA',
    [contractKind.XCHANGE_OMNI_ROUTER]: '',
    [contractKind.THIRD_PARTY_V2_ROUTER]: '',
    [contractKind.THIRD_PARTY_V2_FACTORY]: '',
    [contractKind.LENDING_POOL_RESERVE]: '0x7Ca54e9Aa3128bF15f764fa0f0f93e72b5267000',
    [contractKind.LENDING_POOL_V1]: '0x740015c39da5D148fcA25A467399D00bcE10c001',
    [contractKind.X7_LENDING_DA]: '0x74001e463B3c7dC95D96a1FDBE621678C24D47Da',
    [contractKind.LOAN_TERM_001]: '0x7400165E167479a3c81C8fC8CC3df3D2a92E9017',
    [contractKind.LOAN_TERM_002]: '0x740019A6b3a9cF3bd193986a560B05726143B217',
    [contractKind.LOAN_TERM_003]: '0x74001C747B6cc9091EE63bC9424DfF633FBAc617',
    [contractKind.X7R_TOKEN]: '0x70008F18Fc58928dcE982b0A69C2c21ff80Dca54',
    [contractKind.X7DAO_TOKEN]: '0x7105E64bF67ECA3Ae9b123F0e5Ca2b83b2eF2dA0',
    [contractKind.X7101_TOKEN]: '0x7101a9392EAc53B01e7c07ca3baCa945A56EE105',
    [contractKind.X7102_TOKEN]: '0x7102DC82EF61bfB0410B1b1bF8EA74575bf0A105',
    [contractKind.X7103_TOKEN]: '0x7103eBdbF1f89be2d53EFF9B3CF996C9E775c105',
    [contractKind.X7104_TOKEN]: '0x7104D1f179Cc9cc7fb5c79Be6Da846E3FBC4C105',
    [contractKind.X7105_TOKEN]: '0x7105FAA4a26eD1c67B8B2b41BEc98F06Ee21D105',
    [contractKind.X7100_LIQ_HUB]: '0x7102407afa5d6581AAb694FEB03fEB0e7Cf69ebb',
    [contractKind.X7DAO_LIQ_HUB]: '0x7DA0e45cE7fD8359544Be00a6618215770851ebB',
    [contractKind.X7R_LIQ_HUB]: '0x712E87520f35a0a17A49bcCA4D87c201F0A46EBb',
    [contractKind.X7100_DA]: '0x7100AAcC6047281b105201cb9e0DEcF9Ae5431DA',
    [contractKind.X7DAO_DA]: '0x7da05D75f51056f3B83b43F397668Cf6A5051cDa',
    [contractKind.X7R_DA]: '0x712bC6ddcd97A776B2482531058C629456B93eda',
    [contractKind.X7_TOKEN_TIME_LOCK]: '0x7000F4Cddca46FB77196466C3833Be4E89ab810C',
    [contractKind.X7_TOKEN_BURNER]: '0x70008F0B06060A31515733DB6dCB515c64f3DeAd',
    [contractKind.X7_ECOSYSTEM_SPLITTER]: '0x70001BA1BA4d85739E7B6A7C646B8aba5ed6c888',
    [contractKind.X7_TREASURY_SPLITTER]: '0x70001e625F8C763BE03ffb4135789c7116fef999',
    [contractKind.X7_LIQUIDITY_MAXI_NFT]: '0x7000F8270B955377e047da8202aE3c408186B4F7',
    [contractKind.X7_DEX_MAXI_NFT]: '0x7000b3B5e4e126610A7b7d1Af2D2DE8685c7C4f7',
    [contractKind.X7_BORROWING_MAXI_NFT]: '0x7000D5d7707Bf86b317deC635e459E47b9aBD4F7',
    [contractKind.X7_ECOSYSTEM_MAXI_NFT]: '0x7000CAE2C1016e7De45ec9b54F1835b966BCA4f7',
    [contractKind.MULTICALL]: '0x1F98415757620B543A52E61c46B32eB19261F984',
    [contractKind.USDC]: '0x31eeb2d0f9b6fd8642914ab10f4dd473677d80df',
    [contractKind.DAI]: '',
    [contractKind.WRAPPED_NATIVE_TOKEN]: '',
  },
  [SupportedChainId.SEPOLIA]: {
    [contractKind.XCHANGE_ROUTER]: '0x7DE8063E9fB43321d2100e8Ddae5167F56A50060',
    [contractKind.XCHANGE_DISCOUNT_ROUTER]: '',
    [contractKind.XCHANGE_FACTORY]: '0x7de800467aFcE442019884f51A4A1B9143a34fAc',
    [contractKind.XCHANGE_DISCOUNT_AUTHORITY]: '0x7De8Ab0dD777561cE98B7Ef413F6fd564E89C1dA',
    [contractKind.XCHANGE_OMNI_ROUTER]: '',
    [contractKind.THIRD_PARTY_V2_ROUTER]: '',
    [contractKind.THIRD_PARTY_V2_FACTORY]: '',
    [contractKind.LENDING_POOL_RESERVE]: '0x7Ca54e9Aa3128bF15f764fa0f0f93e72b5267000',
    [contractKind.LENDING_POOL_V1]: '0x740015c39da5D148fcA25A467399D00bcE10c001',
    [contractKind.X7_LENDING_DA]: '0x74001e463B3c7dC95D96a1FDBE621678C24D47Da',
    [contractKind.LOAN_TERM_001]: '0x7400165E167479a3c81C8fC8CC3df3D2a92E9017',
    [contractKind.LOAN_TERM_002]: '0x740019A6b3a9cF3bd193986a560B05726143B217',
    [contractKind.LOAN_TERM_003]: '0x74001C747B6cc9091EE63bC9424DfF633FBAc617',
    [contractKind.X7R_TOKEN]: '0x70008F18Fc58928dcE982b0A69C2c21ff80Dca54',
    [contractKind.X7DAO_TOKEN]: '0x7105E64bF67ECA3Ae9b123F0e5Ca2b83b2eF2dA0',
    [contractKind.X7101_TOKEN]: '0x7101a9392EAc53B01e7c07ca3baCa945A56EE105',
    [contractKind.X7102_TOKEN]: '0x7102DC82EF61bfB0410B1b1bF8EA74575bf0A105',
    [contractKind.X7103_TOKEN]: '0x7103eBdbF1f89be2d53EFF9B3CF996C9E775c105',
    [contractKind.X7104_TOKEN]: '0x7104D1f179Cc9cc7fb5c79Be6Da846E3FBC4C105',
    [contractKind.X7105_TOKEN]: '0x7105FAA4a26eD1c67B8B2b41BEc98F06Ee21D105',
    [contractKind.X7100_LIQ_HUB]: '0x7102407afa5d6581AAb694FEB03fEB0e7Cf69ebb',
    [contractKind.X7DAO_LIQ_HUB]: '0x7DA0e45cE7fD8359544Be00a6618215770851ebB',
    [contractKind.X7R_LIQ_HUB]: '0x712E87520f35a0a17A49bcCA4D87c201F0A46EBb',
    [contractKind.X7100_DA]: '0x7100AAcC6047281b105201cb9e0DEcF9Ae5431DA',
    [contractKind.X7DAO_DA]: '0x7da05D75f51056f3B83b43F397668Cf6A5051cDa',
    [contractKind.X7R_DA]: '0x712bC6ddcd97A776B2482531058C629456B93eda',
    [contractKind.X7_TOKEN_TIME_LOCK]: '0x7000F4Cddca46FB77196466C3833Be4E89ab810C',
    [contractKind.X7_TOKEN_BURNER]: '0x70008F0B06060A31515733DB6dCB515c64f3DeAd',
    [contractKind.X7_ECOSYSTEM_SPLITTER]: '0x70001BA1BA4d85739E7B6A7C646B8aba5ed6c888',
    [contractKind.X7_TREASURY_SPLITTER]: '0x70001e625F8C763BE03ffb4135789c7116fef999',
    [contractKind.X7_LIQUIDITY_MAXI_NFT]: '0x7000F8270B955377e047da8202aE3c408186B4F7',
    [contractKind.X7_DEX_MAXI_NFT]: '0x7000b3B5e4e126610A7b7d1Af2D2DE8685c7C4f7',
    [contractKind.X7_BORROWING_MAXI_NFT]: '0x7000D5d7707Bf86b317deC635e459E47b9aBD4F7',
    [contractKind.X7_ECOSYSTEM_MAXI_NFT]: '0x7000CAE2C1016e7De45ec9b54F1835b966BCA4f7',
    [contractKind.MULTICALL]: '0x1F98415757620B543A52E61c46B32eB19261F984',
    [contractKind.USDC]: '0x31eeb2d0f9b6fd8642914ab10f4dd473677d80df',
    [contractKind.DAI]: '',
    [contractKind.WRAPPED_NATIVE_TOKEN]: '',
  },
  [SupportedChainId.ARBITRUM_ONE]: {
    [contractKind.XCHANGE_ROUTER]: '0x7DE8063E9fB43321d2100e8Ddae5167F56A50060',
    [contractKind.XCHANGE_DISCOUNT_ROUTER]: '',
    [contractKind.XCHANGE_FACTORY]: '0x7de800467aFcE442019884f51A4A1B9143a34fAc',
    [contractKind.XCHANGE_DISCOUNT_AUTHORITY]: '0x7De8Ab0dD777561cE98B7Ef413F6fd564E89C1dA',
    [contractKind.XCHANGE_OMNI_ROUTER]: '',
    [contractKind.THIRD_PARTY_V2_ROUTER]: '',
    [contractKind.THIRD_PARTY_V2_FACTORY]: '',
    [contractKind.LENDING_POOL_RESERVE]: '0x7Ca54e9Aa3128bF15f764fa0f0f93e72b5267000',
    [contractKind.LENDING_POOL_V1]: '0x740015c39da5D148fcA25A467399D00bcE10c001',
    [contractKind.X7_LENDING_DA]: '0x74001e463B3c7dC95D96a1FDBE621678C24D47Da',
    [contractKind.LOAN_TERM_001]: '0x7400165E167479a3c81C8fC8CC3df3D2a92E9017',
    [contractKind.LOAN_TERM_002]: '0x740019A6b3a9cF3bd193986a560B05726143B217',
    [contractKind.LOAN_TERM_003]: '0x74001C747B6cc9091EE63bC9424DfF633FBAc617',
    [contractKind.X7R_TOKEN]: '0x70008F18Fc58928dcE982b0A69C2c21ff80Dca54',
    [contractKind.X7DAO_TOKEN]: '0x7105E64bF67ECA3Ae9b123F0e5Ca2b83b2eF2dA0',
    [contractKind.X7101_TOKEN]: '0x7101a9392EAc53B01e7c07ca3baCa945A56EE105',
    [contractKind.X7102_TOKEN]: '0x7102DC82EF61bfB0410B1b1bF8EA74575bf0A105',
    [contractKind.X7103_TOKEN]: '0x7103eBdbF1f89be2d53EFF9B3CF996C9E775c105',
    [contractKind.X7104_TOKEN]: '0x7104D1f179Cc9cc7fb5c79Be6Da846E3FBC4C105',
    [contractKind.X7105_TOKEN]: '0x7105FAA4a26eD1c67B8B2b41BEc98F06Ee21D105',
    [contractKind.X7100_LIQ_HUB]: '0x7102407afa5d6581AAb694FEB03fEB0e7Cf69ebb',
    [contractKind.X7DAO_LIQ_HUB]: '0x7DA0e45cE7fD8359544Be00a6618215770851ebB',
    [contractKind.X7R_LIQ_HUB]: '0x712E87520f35a0a17A49bcCA4D87c201F0A46EBb',
    [contractKind.X7100_DA]: '0x7100AAcC6047281b105201cb9e0DEcF9Ae5431DA',
    [contractKind.X7DAO_DA]: '0x7da05D75f51056f3B83b43F397668Cf6A5051cDa',
    [contractKind.X7R_DA]: '0x712bC6ddcd97A776B2482531058C629456B93eda',
    [contractKind.X7_TOKEN_TIME_LOCK]: '0x7000F4Cddca46FB77196466C3833Be4E89ab810C',
    [contractKind.X7_TOKEN_BURNER]: '0x70008F0B06060A31515733DB6dCB515c64f3DeAd',
    [contractKind.X7_ECOSYSTEM_SPLITTER]: '0x70001BA1BA4d85739E7B6A7C646B8aba5ed6c888',
    [contractKind.X7_TREASURY_SPLITTER]: '0x70001e625F8C763BE03ffb4135789c7116fef999',
    [contractKind.X7_LIQUIDITY_MAXI_NFT]: '0x7000F8270B955377e047da8202aE3c408186B4F7',
    [contractKind.X7_DEX_MAXI_NFT]: '0x7000b3B5e4e126610A7b7d1Af2D2DE8685c7C4f7',
    [contractKind.X7_BORROWING_MAXI_NFT]: '0x7000D5d7707Bf86b317deC635e459E47b9aBD4F7',
    [contractKind.X7_ECOSYSTEM_MAXI_NFT]: '0x7000CAE2C1016e7De45ec9b54F1835b966BCA4f7',
    [contractKind.MULTICALL]: '0x1F98415757620B543A52E61c46B32eB19261F984',
    [contractKind.USDC]: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
    [contractKind.DAI]: '',
    [contractKind.WRAPPED_NATIVE_TOKEN]: '',
  },
  [SupportedChainId.ARBITRUM_RINKEBY]: {
    [contractKind.XCHANGE_ROUTER]: '0x7DE8063E9fB43321d2100e8Ddae5167F56A50060',
    [contractKind.XCHANGE_DISCOUNT_ROUTER]: '',
    [contractKind.XCHANGE_FACTORY]: '0x7de800467aFcE442019884f51A4A1B9143a34fAc',
    [contractKind.XCHANGE_DISCOUNT_AUTHORITY]: '0x7De8Ab0dD777561cE98B7Ef413F6fd564E89C1dA',
    [contractKind.XCHANGE_OMNI_ROUTER]: '',
    [contractKind.THIRD_PARTY_V2_ROUTER]: '',
    [contractKind.THIRD_PARTY_V2_FACTORY]: '',
    [contractKind.LENDING_POOL_RESERVE]: '0x7Ca54e9Aa3128bF15f764fa0f0f93e72b5267000',
    [contractKind.LENDING_POOL_V1]: '0x740015c39da5D148fcA25A467399D00bcE10c001',
    [contractKind.X7_LENDING_DA]: '0x74001e463B3c7dC95D96a1FDBE621678C24D47Da',
    [contractKind.LOAN_TERM_001]: '0x7400165E167479a3c81C8fC8CC3df3D2a92E9017',
    [contractKind.LOAN_TERM_002]: '0x740019A6b3a9cF3bd193986a560B05726143B217',
    [contractKind.LOAN_TERM_003]: '0x74001C747B6cc9091EE63bC9424DfF633FBAc617',
    [contractKind.X7R_TOKEN]: '0x70008F18Fc58928dcE982b0A69C2c21ff80Dca54',
    [contractKind.X7DAO_TOKEN]: '0x7105E64bF67ECA3Ae9b123F0e5Ca2b83b2eF2dA0',
    [contractKind.X7101_TOKEN]: '0x7101a9392EAc53B01e7c07ca3baCa945A56EE105',
    [contractKind.X7102_TOKEN]: '0x7102DC82EF61bfB0410B1b1bF8EA74575bf0A105',
    [contractKind.X7103_TOKEN]: '0x7103eBdbF1f89be2d53EFF9B3CF996C9E775c105',
    [contractKind.X7104_TOKEN]: '0x7104D1f179Cc9cc7fb5c79Be6Da846E3FBC4C105',
    [contractKind.X7105_TOKEN]: '0x7105FAA4a26eD1c67B8B2b41BEc98F06Ee21D105',
    [contractKind.X7100_LIQ_HUB]: '0x7102407afa5d6581AAb694FEB03fEB0e7Cf69ebb',
    [contractKind.X7DAO_LIQ_HUB]: '0x7DA0e45cE7fD8359544Be00a6618215770851ebB',
    [contractKind.X7R_LIQ_HUB]: '0x712E87520f35a0a17A49bcCA4D87c201F0A46EBb',
    [contractKind.X7100_DA]: '0x7100AAcC6047281b105201cb9e0DEcF9Ae5431DA',
    [contractKind.X7DAO_DA]: '0x7da05D75f51056f3B83b43F397668Cf6A5051cDa',
    [contractKind.X7R_DA]: '0x712bC6ddcd97A776B2482531058C629456B93eda',
    [contractKind.X7_TOKEN_TIME_LOCK]: '0x7000F4Cddca46FB77196466C3833Be4E89ab810C',
    [contractKind.X7_TOKEN_BURNER]: '0x70008F0B06060A31515733DB6dCB515c64f3DeAd',
    [contractKind.X7_ECOSYSTEM_SPLITTER]: '0x70001BA1BA4d85739E7B6A7C646B8aba5ed6c888',
    [contractKind.X7_TREASURY_SPLITTER]: '0x70001e625F8C763BE03ffb4135789c7116fef999',
    [contractKind.X7_LIQUIDITY_MAXI_NFT]: '0x7000F8270B955377e047da8202aE3c408186B4F7',
    [contractKind.X7_DEX_MAXI_NFT]: '0x7000b3B5e4e126610A7b7d1Af2D2DE8685c7C4f7',
    [contractKind.X7_BORROWING_MAXI_NFT]: '0x7000D5d7707Bf86b317deC635e459E47b9aBD4F7',
    [contractKind.X7_ECOSYSTEM_MAXI_NFT]: '0x7000CAE2C1016e7De45ec9b54F1835b966BCA4f7',
    [contractKind.MULTICALL]: '0x1F98415757620B543A52E61c46B32eB19261F984',
    [contractKind.USDC]: '0x09b98f8b2395d076514037ff7d39a091a536206c',
    [contractKind.DAI]: '',
    [contractKind.WRAPPED_NATIVE_TOKEN]: '',
  },
  [SupportedChainId.OPTIMISM]: {
    [contractKind.XCHANGE_ROUTER]: '0x7DE8063E9fB43321d2100e8Ddae5167F56A50060',
    [contractKind.XCHANGE_DISCOUNT_ROUTER]: '',
    [contractKind.XCHANGE_FACTORY]: '0x7de800467aFcE442019884f51A4A1B9143a34fAc',
    [contractKind.XCHANGE_DISCOUNT_AUTHORITY]: '0x7De8Ab0dD777561cE98B7Ef413F6fd564E89C1dA',
    [contractKind.XCHANGE_OMNI_ROUTER]: '',
    [contractKind.THIRD_PARTY_V2_ROUTER]: '',
    [contractKind.THIRD_PARTY_V2_FACTORY]: '',
    [contractKind.LENDING_POOL_RESERVE]: '0x7Ca54e9Aa3128bF15f764fa0f0f93e72b5267000',
    [contractKind.LENDING_POOL_V1]: '0x740015c39da5D148fcA25A467399D00bcE10c001',
    [contractKind.X7_LENDING_DA]: '0x74001e463B3c7dC95D96a1FDBE621678C24D47Da',
    [contractKind.LOAN_TERM_001]: '0x7400165E167479a3c81C8fC8CC3df3D2a92E9017',
    [contractKind.LOAN_TERM_002]: '0x740019A6b3a9cF3bd193986a560B05726143B217',
    [contractKind.LOAN_TERM_003]: '0x74001C747B6cc9091EE63bC9424DfF633FBAc617',
    [contractKind.X7R_TOKEN]: '0x70008F18Fc58928dcE982b0A69C2c21ff80Dca54',
    [contractKind.X7DAO_TOKEN]: '0x7105E64bF67ECA3Ae9b123F0e5Ca2b83b2eF2dA0',
    [contractKind.X7101_TOKEN]: '0x7101a9392EAc53B01e7c07ca3baCa945A56EE105',
    [contractKind.X7102_TOKEN]: '0x7102DC82EF61bfB0410B1b1bF8EA74575bf0A105',
    [contractKind.X7103_TOKEN]: '0x7103eBdbF1f89be2d53EFF9B3CF996C9E775c105',
    [contractKind.X7104_TOKEN]: '0x7104D1f179Cc9cc7fb5c79Be6Da846E3FBC4C105',
    [contractKind.X7105_TOKEN]: '0x7105FAA4a26eD1c67B8B2b41BEc98F06Ee21D105',
    [contractKind.X7100_LIQ_HUB]: '0x7102407afa5d6581AAb694FEB03fEB0e7Cf69ebb',
    [contractKind.X7DAO_LIQ_HUB]: '0x7DA0e45cE7fD8359544Be00a6618215770851ebB',
    [contractKind.X7R_LIQ_HUB]: '0x712E87520f35a0a17A49bcCA4D87c201F0A46EBb',
    [contractKind.X7100_DA]: '0x7100AAcC6047281b105201cb9e0DEcF9Ae5431DA',
    [contractKind.X7DAO_DA]: '0x7da05D75f51056f3B83b43F397668Cf6A5051cDa',
    [contractKind.X7R_DA]: '0x712bC6ddcd97A776B2482531058C629456B93eda',
    [contractKind.X7_TOKEN_TIME_LOCK]: '0x7000F4Cddca46FB77196466C3833Be4E89ab810C',
    [contractKind.X7_TOKEN_BURNER]: '0x70008F0B06060A31515733DB6dCB515c64f3DeAd',
    [contractKind.X7_ECOSYSTEM_SPLITTER]: '0x70001BA1BA4d85739E7B6A7C646B8aba5ed6c888',
    [contractKind.X7_TREASURY_SPLITTER]: '0x70001e625F8C763BE03ffb4135789c7116fef999',
    [contractKind.X7_LIQUIDITY_MAXI_NFT]: '0x7000F8270B955377e047da8202aE3c408186B4F7',
    [contractKind.X7_DEX_MAXI_NFT]: '0x7000b3B5e4e126610A7b7d1Af2D2DE8685c7C4f7',
    [contractKind.X7_BORROWING_MAXI_NFT]: '0x7000D5d7707Bf86b317deC635e459E47b9aBD4F7',
    [contractKind.X7_ECOSYSTEM_MAXI_NFT]: '0x7000CAE2C1016e7De45ec9b54F1835b966BCA4f7',
    [contractKind.MULTICALL]: '0x1F98415757620B543A52E61c46B32eB19261F984',
    [contractKind.USDC]: '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
    [contractKind.DAI]: '',
    [contractKind.WRAPPED_NATIVE_TOKEN]: '',
  },
  [SupportedChainId.OPTIMISM_GOERLI]: {
    [contractKind.XCHANGE_ROUTER]: '0x7DE8063E9fB43321d2100e8Ddae5167F56A50060',
    [contractKind.XCHANGE_DISCOUNT_ROUTER]: '',
    [contractKind.XCHANGE_FACTORY]: '0x7de800467aFcE442019884f51A4A1B9143a34fAc',
    [contractKind.XCHANGE_DISCOUNT_AUTHORITY]: '0x7De8Ab0dD777561cE98B7Ef413F6fd564E89C1dA',
    [contractKind.XCHANGE_OMNI_ROUTER]: '',
    [contractKind.THIRD_PARTY_V2_ROUTER]: '',
    [contractKind.THIRD_PARTY_V2_FACTORY]: '',
    [contractKind.LENDING_POOL_RESERVE]: '0x7Ca54e9Aa3128bF15f764fa0f0f93e72b5267000',
    [contractKind.LENDING_POOL_V1]: '0x740015c39da5D148fcA25A467399D00bcE10c001',
    [contractKind.X7_LENDING_DA]: '0x74001e463B3c7dC95D96a1FDBE621678C24D47Da',
    [contractKind.LOAN_TERM_001]: '0x7400165E167479a3c81C8fC8CC3df3D2a92E9017',
    [contractKind.LOAN_TERM_002]: '0x740019A6b3a9cF3bd193986a560B05726143B217',
    [contractKind.LOAN_TERM_003]: '0x74001C747B6cc9091EE63bC9424DfF633FBAc617',
    [contractKind.X7R_TOKEN]: '0x70008F18Fc58928dcE982b0A69C2c21ff80Dca54',
    [contractKind.X7DAO_TOKEN]: '0x7105E64bF67ECA3Ae9b123F0e5Ca2b83b2eF2dA0',
    [contractKind.X7101_TOKEN]: '0x7101a9392EAc53B01e7c07ca3baCa945A56EE105',
    [contractKind.X7102_TOKEN]: '0x7102DC82EF61bfB0410B1b1bF8EA74575bf0A105',
    [contractKind.X7103_TOKEN]: '0x7103eBdbF1f89be2d53EFF9B3CF996C9E775c105',
    [contractKind.X7104_TOKEN]: '0x7104D1f179Cc9cc7fb5c79Be6Da846E3FBC4C105',
    [contractKind.X7105_TOKEN]: '0x7105FAA4a26eD1c67B8B2b41BEc98F06Ee21D105',
    [contractKind.X7100_LIQ_HUB]: '0x7102407afa5d6581AAb694FEB03fEB0e7Cf69ebb',
    [contractKind.X7DAO_LIQ_HUB]: '0x7DA0e45cE7fD8359544Be00a6618215770851ebB',
    [contractKind.X7R_LIQ_HUB]: '0x712E87520f35a0a17A49bcCA4D87c201F0A46EBb',
    [contractKind.X7100_DA]: '0x7100AAcC6047281b105201cb9e0DEcF9Ae5431DA',
    [contractKind.X7DAO_DA]: '0x7da05D75f51056f3B83b43F397668Cf6A5051cDa',
    [contractKind.X7R_DA]: '0x712bC6ddcd97A776B2482531058C629456B93eda',
    [contractKind.X7_TOKEN_TIME_LOCK]: '0x7000F4Cddca46FB77196466C3833Be4E89ab810C',
    [contractKind.X7_TOKEN_BURNER]: '0x70008F0B06060A31515733DB6dCB515c64f3DeAd',
    [contractKind.X7_ECOSYSTEM_SPLITTER]: '0x70001BA1BA4d85739E7B6A7C646B8aba5ed6c888',
    [contractKind.X7_TREASURY_SPLITTER]: '0x70001e625F8C763BE03ffb4135789c7116fef999',
    [contractKind.X7_LIQUIDITY_MAXI_NFT]: '0x7000F8270B955377e047da8202aE3c408186B4F7',
    [contractKind.X7_DEX_MAXI_NFT]: '0x7000b3B5e4e126610A7b7d1Af2D2DE8685c7C4f7',
    [contractKind.X7_BORROWING_MAXI_NFT]: '0x7000D5d7707Bf86b317deC635e459E47b9aBD4F7',
    [contractKind.X7_ECOSYSTEM_MAXI_NFT]: '0x7000CAE2C1016e7De45ec9b54F1835b966BCA4f7',
    [contractKind.MULTICALL]: '',
    [contractKind.USDC]: '0x7E07E15D2a87A24492740D16f5bdF58c16db0c4E',
    [contractKind.DAI]: '',
    [contractKind.WRAPPED_NATIVE_TOKEN]: '',
  },
  [SupportedChainId.POLYGON]: {
    [contractKind.XCHANGE_ROUTER]: '0x7DE8063E9fB43321d2100e8Ddae5167F56A50060',
    [contractKind.XCHANGE_DISCOUNT_ROUTER]: '',
    [contractKind.XCHANGE_FACTORY]: '0x7de800467aFcE442019884f51A4A1B9143a34fAc',
    [contractKind.XCHANGE_DISCOUNT_AUTHORITY]: '0x7De8Ab0dD777561cE98B7Ef413F6fd564E89C1dA',
    [contractKind.XCHANGE_OMNI_ROUTER]: '',
    [contractKind.THIRD_PARTY_V2_ROUTER]: '',
    [contractKind.THIRD_PARTY_V2_FACTORY]: '',
    [contractKind.LENDING_POOL_RESERVE]: '0x7Ca54e9Aa3128bF15f764fa0f0f93e72b5267000',
    [contractKind.LENDING_POOL_V1]: '0x740015c39da5D148fcA25A467399D00bcE10c001',
    [contractKind.X7_LENDING_DA]: '0x74001e463B3c7dC95D96a1FDBE621678C24D47Da',
    [contractKind.LOAN_TERM_001]: '0x7400165E167479a3c81C8fC8CC3df3D2a92E9017',
    [contractKind.LOAN_TERM_002]: '0x740019A6b3a9cF3bd193986a560B05726143B217',
    [contractKind.LOAN_TERM_003]: '0x74001C747B6cc9091EE63bC9424DfF633FBAc617',
    [contractKind.X7R_TOKEN]: '0x70008F18Fc58928dcE982b0A69C2c21ff80Dca54',
    [contractKind.X7DAO_TOKEN]: '0x7105E64bF67ECA3Ae9b123F0e5Ca2b83b2eF2dA0',
    [contractKind.X7101_TOKEN]: '0x7101a9392EAc53B01e7c07ca3baCa945A56EE105',
    [contractKind.X7102_TOKEN]: '0x7102DC82EF61bfB0410B1b1bF8EA74575bf0A105',
    [contractKind.X7103_TOKEN]: '0x7103eBdbF1f89be2d53EFF9B3CF996C9E775c105',
    [contractKind.X7104_TOKEN]: '0x7104D1f179Cc9cc7fb5c79Be6Da846E3FBC4C105',
    [contractKind.X7105_TOKEN]: '0x7105FAA4a26eD1c67B8B2b41BEc98F06Ee21D105',
    [contractKind.X7100_LIQ_HUB]: '0x7102407afa5d6581AAb694FEB03fEB0e7Cf69ebb',
    [contractKind.X7DAO_LIQ_HUB]: '0x7DA0e45cE7fD8359544Be00a6618215770851ebB',
    [contractKind.X7R_LIQ_HUB]: '0x712E87520f35a0a17A49bcCA4D87c201F0A46EBb',
    [contractKind.X7100_DA]: '0x7100AAcC6047281b105201cb9e0DEcF9Ae5431DA',
    [contractKind.X7DAO_DA]: '0x7da05D75f51056f3B83b43F397668Cf6A5051cDa',
    [contractKind.X7R_DA]: '0x712bC6ddcd97A776B2482531058C629456B93eda',
    [contractKind.X7_TOKEN_TIME_LOCK]: '0x7000F4Cddca46FB77196466C3833Be4E89ab810C',
    [contractKind.X7_TOKEN_BURNER]: '0x70008F0B06060A31515733DB6dCB515c64f3DeAd',
    [contractKind.X7_ECOSYSTEM_SPLITTER]: '0x70001BA1BA4d85739E7B6A7C646B8aba5ed6c888',
    [contractKind.X7_TREASURY_SPLITTER]: '0x70001e625F8C763BE03ffb4135789c7116fef999',
    [contractKind.X7_LIQUIDITY_MAXI_NFT]: '0x7000F8270B955377e047da8202aE3c408186B4F7',
    [contractKind.X7_DEX_MAXI_NFT]: '0x7000b3B5e4e126610A7b7d1Af2D2DE8685c7C4f7',
    [contractKind.X7_BORROWING_MAXI_NFT]: '0x7000D5d7707Bf86b317deC635e459E47b9aBD4F7',
    [contractKind.X7_ECOSYSTEM_MAXI_NFT]: '0x7000CAE2C1016e7De45ec9b54F1835b966BCA4f7',
    [contractKind.MULTICALL]: '0x1F98415757620B543A52E61c46B32eB19261F984',
    [contractKind.USDC]: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    [contractKind.DAI]: '',
    [contractKind.WRAPPED_NATIVE_TOKEN]: '',
  },
  [SupportedChainId.POLYGON_MUMBAI]: {
    [contractKind.XCHANGE_ROUTER]: '0x7DE8063E9fB43321d2100e8Ddae5167F56A50060',
    [contractKind.XCHANGE_DISCOUNT_ROUTER]: '',
    [contractKind.XCHANGE_FACTORY]: '0x7de800467aFcE442019884f51A4A1B9143a34fAc',
    [contractKind.XCHANGE_DISCOUNT_AUTHORITY]: '0x7De8Ab0dD777561cE98B7Ef413F6fd564E89C1dA',
    [contractKind.XCHANGE_OMNI_ROUTER]: '',
    [contractKind.THIRD_PARTY_V2_ROUTER]: '',
    [contractKind.THIRD_PARTY_V2_FACTORY]: '',
    [contractKind.LENDING_POOL_RESERVE]: '0x7Ca54e9Aa3128bF15f764fa0f0f93e72b5267000',
    [contractKind.LENDING_POOL_V1]: '0x740015c39da5D148fcA25A467399D00bcE10c001',
    [contractKind.X7_LENDING_DA]: '0x74001e463B3c7dC95D96a1FDBE621678C24D47Da',
    [contractKind.LOAN_TERM_001]: '0x7400165E167479a3c81C8fC8CC3df3D2a92E9017',
    [contractKind.LOAN_TERM_002]: '0x740019A6b3a9cF3bd193986a560B05726143B217',
    [contractKind.LOAN_TERM_003]: '0x74001C747B6cc9091EE63bC9424DfF633FBAc617',
    [contractKind.X7R_TOKEN]: '0x70008F18Fc58928dcE982b0A69C2c21ff80Dca54',
    [contractKind.X7DAO_TOKEN]: '0x7105E64bF67ECA3Ae9b123F0e5Ca2b83b2eF2dA0',
    [contractKind.X7101_TOKEN]: '0x7101a9392EAc53B01e7c07ca3baCa945A56EE105',
    [contractKind.X7102_TOKEN]: '0x7102DC82EF61bfB0410B1b1bF8EA74575bf0A105',
    [contractKind.X7103_TOKEN]: '0x7103eBdbF1f89be2d53EFF9B3CF996C9E775c105',
    [contractKind.X7104_TOKEN]: '0x7104D1f179Cc9cc7fb5c79Be6Da846E3FBC4C105',
    [contractKind.X7105_TOKEN]: '0x7105FAA4a26eD1c67B8B2b41BEc98F06Ee21D105',
    [contractKind.X7100_LIQ_HUB]: '0x7102407afa5d6581AAb694FEB03fEB0e7Cf69ebb',
    [contractKind.X7DAO_LIQ_HUB]: '0x7DA0e45cE7fD8359544Be00a6618215770851ebB',
    [contractKind.X7R_LIQ_HUB]: '0x712E87520f35a0a17A49bcCA4D87c201F0A46EBb',
    [contractKind.X7100_DA]: '0x7100AAcC6047281b105201cb9e0DEcF9Ae5431DA',
    [contractKind.X7DAO_DA]: '0x7da05D75f51056f3B83b43F397668Cf6A5051cDa',
    [contractKind.X7R_DA]: '0x712bC6ddcd97A776B2482531058C629456B93eda',
    [contractKind.X7_TOKEN_TIME_LOCK]: '0x7000F4Cddca46FB77196466C3833Be4E89ab810C',
    [contractKind.X7_TOKEN_BURNER]: '0x70008F0B06060A31515733DB6dCB515c64f3DeAd',
    [contractKind.X7_ECOSYSTEM_SPLITTER]: '0x70001BA1BA4d85739E7B6A7C646B8aba5ed6c888',
    [contractKind.X7_TREASURY_SPLITTER]: '0x70001e625F8C763BE03ffb4135789c7116fef999',
    [contractKind.X7_LIQUIDITY_MAXI_NFT]: '0x7000F8270B955377e047da8202aE3c408186B4F7',
    [contractKind.X7_DEX_MAXI_NFT]: '0x7000b3B5e4e126610A7b7d1Af2D2DE8685c7C4f7',
    [contractKind.X7_BORROWING_MAXI_NFT]: '0x7000D5d7707Bf86b317deC635e459E47b9aBD4F7',
    [contractKind.X7_ECOSYSTEM_MAXI_NFT]: '0x7000CAE2C1016e7De45ec9b54F1835b966BCA4f7',
    [contractKind.MULTICALL]: '',
    [contractKind.USDC]: '0xe11a86849d99f524cac3e7a0ec1241828e332c62',
    [contractKind.DAI]: '',
    [contractKind.WRAPPED_NATIVE_TOKEN]: '',
  },
  [SupportedChainId.CELO]: {
    [contractKind.XCHANGE_ROUTER]: '0x7DE8063E9fB43321d2100e8Ddae5167F56A50060',
    [contractKind.XCHANGE_DISCOUNT_ROUTER]: '',
    [contractKind.XCHANGE_FACTORY]: '0x7de800467aFcE442019884f51A4A1B9143a34fAc',
    [contractKind.XCHANGE_DISCOUNT_AUTHORITY]: '0x7De8Ab0dD777561cE98B7Ef413F6fd564E89C1dA',
    [contractKind.XCHANGE_OMNI_ROUTER]: '',
    [contractKind.THIRD_PARTY_V2_ROUTER]: '',
    [contractKind.THIRD_PARTY_V2_FACTORY]: '',
    [contractKind.LENDING_POOL_RESERVE]: '0x7Ca54e9Aa3128bF15f764fa0f0f93e72b5267000',
    [contractKind.LENDING_POOL_V1]: '0x740015c39da5D148fcA25A467399D00bcE10c001',
    [contractKind.X7_LENDING_DA]: '0x74001e463B3c7dC95D96a1FDBE621678C24D47Da',
    [contractKind.LOAN_TERM_001]: '0x7400165E167479a3c81C8fC8CC3df3D2a92E9017',
    [contractKind.LOAN_TERM_002]: '0x740019A6b3a9cF3bd193986a560B05726143B217',
    [contractKind.LOAN_TERM_003]: '0x74001C747B6cc9091EE63bC9424DfF633FBAc617',
    [contractKind.X7R_TOKEN]: '0x70008F18Fc58928dcE982b0A69C2c21ff80Dca54',
    [contractKind.X7DAO_TOKEN]: '0x7105E64bF67ECA3Ae9b123F0e5Ca2b83b2eF2dA0',
    [contractKind.X7101_TOKEN]: '0x7101a9392EAc53B01e7c07ca3baCa945A56EE105',
    [contractKind.X7102_TOKEN]: '0x7102DC82EF61bfB0410B1b1bF8EA74575bf0A105',
    [contractKind.X7103_TOKEN]: '0x7103eBdbF1f89be2d53EFF9B3CF996C9E775c105',
    [contractKind.X7104_TOKEN]: '0x7104D1f179Cc9cc7fb5c79Be6Da846E3FBC4C105',
    [contractKind.X7105_TOKEN]: '0x7105FAA4a26eD1c67B8B2b41BEc98F06Ee21D105',
    [contractKind.X7100_LIQ_HUB]: '0x7102407afa5d6581AAb694FEB03fEB0e7Cf69ebb',
    [contractKind.X7DAO_LIQ_HUB]: '0x7DA0e45cE7fD8359544Be00a6618215770851ebB',
    [contractKind.X7R_LIQ_HUB]: '0x712E87520f35a0a17A49bcCA4D87c201F0A46EBb',
    [contractKind.X7100_DA]: '0x7100AAcC6047281b105201cb9e0DEcF9Ae5431DA',
    [contractKind.X7DAO_DA]: '0x7da05D75f51056f3B83b43F397668Cf6A5051cDa',
    [contractKind.X7R_DA]: '0x712bC6ddcd97A776B2482531058C629456B93eda',
    [contractKind.X7_TOKEN_TIME_LOCK]: '0x7000F4Cddca46FB77196466C3833Be4E89ab810C',
    [contractKind.X7_TOKEN_BURNER]: '0x70008F0B06060A31515733DB6dCB515c64f3DeAd',
    [contractKind.X7_ECOSYSTEM_SPLITTER]: '0x70001BA1BA4d85739E7B6A7C646B8aba5ed6c888',
    [contractKind.X7_TREASURY_SPLITTER]: '0x70001e625F8C763BE03ffb4135789c7116fef999',
    [contractKind.X7_LIQUIDITY_MAXI_NFT]: '0x7000F8270B955377e047da8202aE3c408186B4F7',
    [contractKind.X7_DEX_MAXI_NFT]: '0x7000b3B5e4e126610A7b7d1Af2D2DE8685c7C4f7',
    [contractKind.X7_BORROWING_MAXI_NFT]: '0x7000D5d7707Bf86b317deC635e459E47b9aBD4F7',
    [contractKind.X7_ECOSYSTEM_MAXI_NFT]: '0x7000CAE2C1016e7De45ec9b54F1835b966BCA4f7',
    [contractKind.MULTICALL]: '0x633987602DE5C4F337e3DbF265303A1080324204',
    [contractKind.USDC]: '0x37f750B7cC259A2f741AF45294f6a16572CF5cAd',
    [contractKind.DAI]: '',
    [contractKind.WRAPPED_NATIVE_TOKEN]: '',
  },
  [SupportedChainId.CELO_ALFAJORES]: {
    [contractKind.XCHANGE_ROUTER]: '0x7DE8063E9fB43321d2100e8Ddae5167F56A50060',
    [contractKind.XCHANGE_DISCOUNT_ROUTER]: '',
    [contractKind.XCHANGE_FACTORY]: '0x7de800467aFcE442019884f51A4A1B9143a34fAc',
    [contractKind.XCHANGE_DISCOUNT_AUTHORITY]: '0x7De8Ab0dD777561cE98B7Ef413F6fd564E89C1dA',
    [contractKind.XCHANGE_OMNI_ROUTER]: '',
    [contractKind.THIRD_PARTY_V2_ROUTER]: '',
    [contractKind.THIRD_PARTY_V2_FACTORY]: '',
    [contractKind.LENDING_POOL_RESERVE]: '0x7Ca54e9Aa3128bF15f764fa0f0f93e72b5267000',
    [contractKind.LENDING_POOL_V1]: '0x740015c39da5D148fcA25A467399D00bcE10c001',
    [contractKind.X7_LENDING_DA]: '0x74001e463B3c7dC95D96a1FDBE621678C24D47Da',
    [contractKind.LOAN_TERM_001]: '0x7400165E167479a3c81C8fC8CC3df3D2a92E9017',
    [contractKind.LOAN_TERM_002]: '0x740019A6b3a9cF3bd193986a560B05726143B217',
    [contractKind.LOAN_TERM_003]: '0x74001C747B6cc9091EE63bC9424DfF633FBAc617',
    [contractKind.X7R_TOKEN]: '0x70008F18Fc58928dcE982b0A69C2c21ff80Dca54',
    [contractKind.X7DAO_TOKEN]: '0x7105E64bF67ECA3Ae9b123F0e5Ca2b83b2eF2dA0',
    [contractKind.X7101_TOKEN]: '0x7101a9392EAc53B01e7c07ca3baCa945A56EE105',
    [contractKind.X7102_TOKEN]: '0x7102DC82EF61bfB0410B1b1bF8EA74575bf0A105',
    [contractKind.X7103_TOKEN]: '0x7103eBdbF1f89be2d53EFF9B3CF996C9E775c105',
    [contractKind.X7104_TOKEN]: '0x7104D1f179Cc9cc7fb5c79Be6Da846E3FBC4C105',
    [contractKind.X7105_TOKEN]: '0x7105FAA4a26eD1c67B8B2b41BEc98F06Ee21D105',
    [contractKind.X7100_LIQ_HUB]: '0x7102407afa5d6581AAb694FEB03fEB0e7Cf69ebb',
    [contractKind.X7DAO_LIQ_HUB]: '0x7DA0e45cE7fD8359544Be00a6618215770851ebB',
    [contractKind.X7R_LIQ_HUB]: '0x712E87520f35a0a17A49bcCA4D87c201F0A46EBb',
    [contractKind.X7100_DA]: '0x7100AAcC6047281b105201cb9e0DEcF9Ae5431DA',
    [contractKind.X7DAO_DA]: '0x7da05D75f51056f3B83b43F397668Cf6A5051cDa',
    [contractKind.X7R_DA]: '0x712bC6ddcd97A776B2482531058C629456B93eda',
    [contractKind.X7_TOKEN_TIME_LOCK]: '0x7000F4Cddca46FB77196466C3833Be4E89ab810C',
    [contractKind.X7_TOKEN_BURNER]: '0x70008F0B06060A31515733DB6dCB515c64f3DeAd',
    [contractKind.X7_ECOSYSTEM_SPLITTER]: '0x70001BA1BA4d85739E7B6A7C646B8aba5ed6c888',
    [contractKind.X7_TREASURY_SPLITTER]: '0x70001e625F8C763BE03ffb4135789c7116fef999',
    [contractKind.X7_LIQUIDITY_MAXI_NFT]: '0x7000F8270B955377e047da8202aE3c408186B4F7',
    [contractKind.X7_DEX_MAXI_NFT]: '0x7000b3B5e4e126610A7b7d1Af2D2DE8685c7C4f7',
    [contractKind.X7_BORROWING_MAXI_NFT]: '0x7000D5d7707Bf86b317deC635e459E47b9aBD4F7',
    [contractKind.X7_ECOSYSTEM_MAXI_NFT]: '0x7000CAE2C1016e7De45ec9b54F1835b966BCA4f7',
    [contractKind.MULTICALL]: '0x633987602DE5C4F337e3DbF265303A1080324204',
    [contractKind.USDC]: '0x41F4a5d2632b019Ae6CE9625bE3c9CaC143AcC7D',
    [contractKind.DAI]: '',
    [contractKind.WRAPPED_NATIVE_TOKEN]: '',
  },
  [SupportedChainId.MAINNET]: {
    [contractKind.XCHANGE_ROUTER]: '0x7DE8063E9fB43321d2100e8Ddae5167F56A50060',
    [contractKind.XCHANGE_DISCOUNT_ROUTER]: '',
    [contractKind.XCHANGE_FACTORY]: '0x7de800467aFcE442019884f51A4A1B9143a34fAc',
    [contractKind.XCHANGE_DISCOUNT_AUTHORITY]: '0x7De8Ab0dD777561cE98B7Ef413F6fd564E89C1dA',
    [contractKind.XCHANGE_OMNI_ROUTER]: '',
    [contractKind.THIRD_PARTY_V2_ROUTER]: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
    [contractKind.THIRD_PARTY_V2_FACTORY]: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
    [contractKind.LENDING_POOL_RESERVE]: '0x7Ca54e9Aa3128bF15f764fa0f0f93e72b5267000',
    [contractKind.LENDING_POOL_V1]: '0x740015c39da5D148fcA25A467399D00bcE10c001',
    [contractKind.X7_LENDING_DA]: '0x74001e463B3c7dC95D96a1FDBE621678C24D47Da',
    [contractKind.LOAN_TERM_001]: '0x7400165E167479a3c81C8fC8CC3df3D2a92E9017',
    [contractKind.LOAN_TERM_002]: '0x740019A6b3a9cF3bd193986a560B05726143B217',
    [contractKind.LOAN_TERM_003]: '0x74001C747B6cc9091EE63bC9424DfF633FBAc617',
    [contractKind.X7R_TOKEN]: '0x70008F18Fc58928dcE982b0A69C2c21ff80Dca54',
    [contractKind.X7DAO_TOKEN]: '0x7105E64bF67ECA3Ae9b123F0e5Ca2b83b2eF2dA0',
    [contractKind.X7101_TOKEN]: '0x7101a9392EAc53B01e7c07ca3baCa945A56EE105',
    [contractKind.X7102_TOKEN]: '0x7102DC82EF61bfB0410B1b1bF8EA74575bf0A105',
    [contractKind.X7103_TOKEN]: '0x7103eBdbF1f89be2d53EFF9B3CF996C9E775c105',
    [contractKind.X7104_TOKEN]: '0x7104D1f179Cc9cc7fb5c79Be6Da846E3FBC4C105',
    [contractKind.X7105_TOKEN]: '0x7105FAA4a26eD1c67B8B2b41BEc98F06Ee21D105',
    [contractKind.X7100_LIQ_HUB]: '0x7102407afa5d6581AAb694FEB03fEB0e7Cf69ebb',
    [contractKind.X7DAO_LIQ_HUB]: '0x7DA0e45cE7fD8359544Be00a6618215770851ebB',
    [contractKind.X7R_LIQ_HUB]: '0x712E87520f35a0a17A49bcCA4D87c201F0A46EBb',
    [contractKind.X7100_DA]: '0x7100AAcC6047281b105201cb9e0DEcF9Ae5431DA',
    [contractKind.X7DAO_DA]: '0x7da05D75f51056f3B83b43F397668Cf6A5051cDa',
    [contractKind.X7R_DA]: '0x712bC6ddcd97A776B2482531058C629456B93eda',
    [contractKind.X7_TOKEN_TIME_LOCK]: '0x7000F4Cddca46FB77196466C3833Be4E89ab810C',
    [contractKind.X7_TOKEN_BURNER]: '0x70008F0B06060A31515733DB6dCB515c64f3DeAd',
    [contractKind.X7_ECOSYSTEM_SPLITTER]: '0x70001BA1BA4d85739E7B6A7C646B8aba5ed6c888',
    [contractKind.X7_TREASURY_SPLITTER]: '0x70001e625F8C763BE03ffb4135789c7116fef999',
    [contractKind.X7_LIQUIDITY_MAXI_NFT]: '0x7000F8270B955377e047da8202aE3c408186B4F7',
    [contractKind.X7_DEX_MAXI_NFT]: '0x7000b3B5e4e126610A7b7d1Af2D2DE8685c7C4f7',
    [contractKind.X7_BORROWING_MAXI_NFT]: '0x7000D5d7707Bf86b317deC635e459E47b9aBD4F7',
    [contractKind.X7_ECOSYSTEM_MAXI_NFT]: '0x7000CAE2C1016e7De45ec9b54F1835b966BCA4f7',
    [contractKind.MULTICALL]: '0xFC5fE4fd2F234F5F4e65364DBf95B0C80C42Db37',
    [contractKind.USDC]: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    [contractKind.DAI]: '',
    [contractKind.WRAPPED_NATIVE_TOKEN]: '',
  },
  [SupportedChainId.BNB_SMARTCHAIN]: {
    [contractKind.XCHANGE_ROUTER]: '0x7DE8063E9fB43321d2100e8Ddae5167F56A50060',
    [contractKind.XCHANGE_DISCOUNT_ROUTER]: '',
    [contractKind.XCHANGE_FACTORY]: '0x7de800467aFcE442019884f51A4A1B9143a34fAc',
    [contractKind.XCHANGE_DISCOUNT_AUTHORITY]: '0x7De8Ab0dD777561cE98B7Ef413F6fd564E89C1dA',
    [contractKind.XCHANGE_OMNI_ROUTER]: '',
    [contractKind.THIRD_PARTY_V2_ROUTER]: '',
    [contractKind.THIRD_PARTY_V2_FACTORY]: '',
    [contractKind.LENDING_POOL_RESERVE]: '0x7Ca54e9Aa3128bF15f764fa0f0f93e72b5267000',
    [contractKind.LENDING_POOL_V1]: '0x740015c39da5D148fcA25A467399D00bcE10c001',
    [contractKind.X7_LENDING_DA]: '0x74001e463B3c7dC95D96a1FDBE621678C24D47Da',
    [contractKind.LOAN_TERM_001]: '0x7400165E167479a3c81C8fC8CC3df3D2a92E9017',
    [contractKind.LOAN_TERM_002]: '0x740019A6b3a9cF3bd193986a560B05726143B217',
    [contractKind.LOAN_TERM_003]: '0x74001C747B6cc9091EE63bC9424DfF633FBAc617',
    [contractKind.X7R_TOKEN]: '0x70008F18Fc58928dcE982b0A69C2c21ff80Dca54',
    [contractKind.X7DAO_TOKEN]: '0x7105E64bF67ECA3Ae9b123F0e5Ca2b83b2eF2dA0',
    [contractKind.X7101_TOKEN]: '0x7101a9392EAc53B01e7c07ca3baCa945A56EE105',
    [contractKind.X7102_TOKEN]: '0x7102DC82EF61bfB0410B1b1bF8EA74575bf0A105',
    [contractKind.X7103_TOKEN]: '0x7103eBdbF1f89be2d53EFF9B3CF996C9E775c105',
    [contractKind.X7104_TOKEN]: '0x7104D1f179Cc9cc7fb5c79Be6Da846E3FBC4C105',
    [contractKind.X7105_TOKEN]: '0x7105FAA4a26eD1c67B8B2b41BEc98F06Ee21D105',
    [contractKind.X7100_LIQ_HUB]: '0x7102407afa5d6581AAb694FEB03fEB0e7Cf69ebb',
    [contractKind.X7DAO_LIQ_HUB]: '0x7DA0e45cE7fD8359544Be00a6618215770851ebB',
    [contractKind.X7R_LIQ_HUB]: '0x712E87520f35a0a17A49bcCA4D87c201F0A46EBb',
    [contractKind.X7100_DA]: '0x7100AAcC6047281b105201cb9e0DEcF9Ae5431DA',
    [contractKind.X7DAO_DA]: '0x7da05D75f51056f3B83b43F397668Cf6A5051cDa',
    [contractKind.X7R_DA]: '0x712bC6ddcd97A776B2482531058C629456B93eda',
    [contractKind.X7_TOKEN_TIME_LOCK]: '0x7000F4Cddca46FB77196466C3833Be4E89ab810C',
    [contractKind.X7_TOKEN_BURNER]: '0x70008F0B06060A31515733DB6dCB515c64f3DeAd',
    [contractKind.X7_ECOSYSTEM_SPLITTER]: '0x70001BA1BA4d85739E7B6A7C646B8aba5ed6c888',
    [contractKind.X7_TREASURY_SPLITTER]: '0x70001e625F8C763BE03ffb4135789c7116fef999',
    [contractKind.X7_LIQUIDITY_MAXI_NFT]: '0x7000F8270B955377e047da8202aE3c408186B4F7',
    [contractKind.X7_DEX_MAXI_NFT]: '0x7000b3B5e4e126610A7b7d1Af2D2DE8685c7C4f7',
    [contractKind.X7_BORROWING_MAXI_NFT]: '0x7000D5d7707Bf86b317deC635e459E47b9aBD4F7',
    [contractKind.X7_ECOSYSTEM_MAXI_NFT]: '0x7000CAE2C1016e7De45ec9b54F1835b966BCA4f7',
    [contractKind.MULTICALL]: '0x963Df249eD09c358A4819E39d9Cd5736c3087184',
    [contractKind.USDC]: '0x07865c6e87b9f70255377e024ace6630c1eaa37f',
    [contractKind.DAI]: '',
    [contractKind.WRAPPED_NATIVE_TOKEN]: '',
  },
  [SupportedChainId.BNB_SMARTCHAIN_TESTNET]: {
    [contractKind.XCHANGE_ROUTER]: '0x7DE8063E9fB43321d2100e8Ddae5167F56A50060',
    [contractKind.XCHANGE_DISCOUNT_ROUTER]: '',
    [contractKind.XCHANGE_FACTORY]: '0x7de800467aFcE442019884f51A4A1B9143a34fAc',
    [contractKind.XCHANGE_DISCOUNT_AUTHORITY]: '0x7De8Ab0dD777561cE98B7Ef413F6fd564E89C1dA',
    [contractKind.XCHANGE_OMNI_ROUTER]: '',
    [contractKind.THIRD_PARTY_V2_ROUTER]: '',
    [contractKind.THIRD_PARTY_V2_FACTORY]: '',
    [contractKind.LENDING_POOL_RESERVE]: '0x7Ca54e9Aa3128bF15f764fa0f0f93e72b5267000',
    [contractKind.LENDING_POOL_V1]: '0x740015c39da5D148fcA25A467399D00bcE10c001',
    [contractKind.X7_LENDING_DA]: '0x74001e463B3c7dC95D96a1FDBE621678C24D47Da',
    [contractKind.LOAN_TERM_001]: '0x7400165E167479a3c81C8fC8CC3df3D2a92E9017',
    [contractKind.LOAN_TERM_002]: '0x740019A6b3a9cF3bd193986a560B05726143B217',
    [contractKind.LOAN_TERM_003]: '0x74001C747B6cc9091EE63bC9424DfF633FBAc617',
    [contractKind.X7R_TOKEN]: '0x70008F18Fc58928dcE982b0A69C2c21ff80Dca54',
    [contractKind.X7DAO_TOKEN]: '0x7105E64bF67ECA3Ae9b123F0e5Ca2b83b2eF2dA0',
    [contractKind.X7101_TOKEN]: '0x7101a9392EAc53B01e7c07ca3baCa945A56EE105',
    [contractKind.X7102_TOKEN]: '0x7102DC82EF61bfB0410B1b1bF8EA74575bf0A105',
    [contractKind.X7103_TOKEN]: '0x7103eBdbF1f89be2d53EFF9B3CF996C9E775c105',
    [contractKind.X7104_TOKEN]: '0x7104D1f179Cc9cc7fb5c79Be6Da846E3FBC4C105',
    [contractKind.X7105_TOKEN]: '0x7105FAA4a26eD1c67B8B2b41BEc98F06Ee21D105',
    [contractKind.X7100_LIQ_HUB]: '0x7102407afa5d6581AAb694FEB03fEB0e7Cf69ebb',
    [contractKind.X7DAO_LIQ_HUB]: '0x7DA0e45cE7fD8359544Be00a6618215770851ebB',
    [contractKind.X7R_LIQ_HUB]: '0x712E87520f35a0a17A49bcCA4D87c201F0A46EBb',
    [contractKind.X7100_DA]: '0x7100AAcC6047281b105201cb9e0DEcF9Ae5431DA',
    [contractKind.X7DAO_DA]: '0x7da05D75f51056f3B83b43F397668Cf6A5051cDa',
    [contractKind.X7R_DA]: '0x712bC6ddcd97A776B2482531058C629456B93eda',
    [contractKind.X7_TOKEN_TIME_LOCK]: '0x7000F4Cddca46FB77196466C3833Be4E89ab810C',
    [contractKind.X7_TOKEN_BURNER]: '0x70008F0B06060A31515733DB6dCB515c64f3DeAd',
    [contractKind.X7_ECOSYSTEM_SPLITTER]: '0x70001BA1BA4d85739E7B6A7C646B8aba5ed6c888',
    [contractKind.X7_TREASURY_SPLITTER]: '0x70001e625F8C763BE03ffb4135789c7116fef999',
    [contractKind.X7_LIQUIDITY_MAXI_NFT]: '0x7000F8270B955377e047da8202aE3c408186B4F7',
    [contractKind.X7_DEX_MAXI_NFT]: '0x7000b3B5e4e126610A7b7d1Af2D2DE8685c7C4f7',
    [contractKind.X7_BORROWING_MAXI_NFT]: '0x7000D5d7707Bf86b317deC635e459E47b9aBD4F7',
    [contractKind.X7_ECOSYSTEM_MAXI_NFT]: '0x7000CAE2C1016e7De45ec9b54F1835b966BCA4f7',
    [contractKind.MULTICALL]: '0x1F98415757620B543A52E61c46B32eB19261F984',
    [contractKind.USDC]: '0x07865c6e87b9f70255377e024ace6630c1eaa37f',
    [contractKind.DAI]: '',
    [contractKind.WRAPPED_NATIVE_TOKEN]: '',
  },
  [SupportedChainId.HARDHAT]: {
    [contractKind.XCHANGE_ROUTER]: '0x7DE8063E9fB43321d2100e8Ddae5167F56A50060',
    [contractKind.XCHANGE_DISCOUNT_ROUTER]: '',
    [contractKind.XCHANGE_FACTORY]: '0x7de800467aFcE442019884f51A4A1B9143a34fAc',
    [contractKind.XCHANGE_DISCOUNT_AUTHORITY]: '0x7De8Ab0dD777561cE98B7Ef413F6fd564E89C1dA',
    [contractKind.XCHANGE_OMNI_ROUTER]: '',
    [contractKind.THIRD_PARTY_V2_ROUTER]: '',
    [contractKind.THIRD_PARTY_V2_FACTORY]: '',
    [contractKind.LENDING_POOL_RESERVE]: '0x7Ca54e9Aa3128bF15f764fa0f0f93e72b5267000',
    [contractKind.LENDING_POOL_V1]: '0x740015c39da5D148fcA25A467399D00bcE10c001',
    [contractKind.X7_LENDING_DA]: '0x74001e463B3c7dC95D96a1FDBE621678C24D47Da',
    [contractKind.LOAN_TERM_001]: '0x7400165E167479a3c81C8fC8CC3df3D2a92E9017',
    [contractKind.LOAN_TERM_002]: '0x740019A6b3a9cF3bd193986a560B05726143B217',
    [contractKind.LOAN_TERM_003]: '0x74001C747B6cc9091EE63bC9424DfF633FBAc617',
    [contractKind.X7R_TOKEN]: '0x70008F18Fc58928dcE982b0A69C2c21ff80Dca54',
    [contractKind.X7DAO_TOKEN]: '0x7105E64bF67ECA3Ae9b123F0e5Ca2b83b2eF2dA0',
    [contractKind.X7101_TOKEN]: '0x7101a9392EAc53B01e7c07ca3baCa945A56EE105',
    [contractKind.X7102_TOKEN]: '0x7102DC82EF61bfB0410B1b1bF8EA74575bf0A105',
    [contractKind.X7103_TOKEN]: '0x7103eBdbF1f89be2d53EFF9B3CF996C9E775c105',
    [contractKind.X7104_TOKEN]: '0x7104D1f179Cc9cc7fb5c79Be6Da846E3FBC4C105',
    [contractKind.X7105_TOKEN]: '0x7105FAA4a26eD1c67B8B2b41BEc98F06Ee21D105',
    [contractKind.X7100_LIQ_HUB]: '0x7102407afa5d6581AAb694FEB03fEB0e7Cf69ebb',
    [contractKind.X7DAO_LIQ_HUB]: '0x7DA0e45cE7fD8359544Be00a6618215770851ebB',
    [contractKind.X7R_LIQ_HUB]: '0x712E87520f35a0a17A49bcCA4D87c201F0A46EBb',
    [contractKind.X7100_DA]: '0x7100AAcC6047281b105201cb9e0DEcF9Ae5431DA',
    [contractKind.X7DAO_DA]: '0x7da05D75f51056f3B83b43F397668Cf6A5051cDa',
    [contractKind.X7R_DA]: '0x712bC6ddcd97A776B2482531058C629456B93eda',
    [contractKind.X7_TOKEN_TIME_LOCK]: '0x7000F4Cddca46FB77196466C3833Be4E89ab810C',
    [contractKind.X7_TOKEN_BURNER]: '0x70008F0B06060A31515733DB6dCB515c64f3DeAd',
    [contractKind.X7_ECOSYSTEM_SPLITTER]: '0x70001BA1BA4d85739E7B6A7C646B8aba5ed6c888',
    [contractKind.X7_TREASURY_SPLITTER]: '0x70001e625F8C763BE03ffb4135789c7116fef999',
    [contractKind.X7_LIQUIDITY_MAXI_NFT]: '0x7000F8270B955377e047da8202aE3c408186B4F7',
    [contractKind.X7_DEX_MAXI_NFT]: '0x7000b3B5e4e126610A7b7d1Af2D2DE8685c7C4f7',
    [contractKind.X7_BORROWING_MAXI_NFT]: '0x7000D5d7707Bf86b317deC635e459E47b9aBD4F7',
    [contractKind.X7_ECOSYSTEM_MAXI_NFT]: '0x7000CAE2C1016e7De45ec9b54F1835b966BCA4f7',
    [contractKind.MULTICALL]: '0x1F98415757620B543A52E61c46B32eB19261F984',
    [contractKind.USDC]: '0x07865c6e87b9f70255377e024ace6630c1eaa37f',
    [contractKind.DAI]: '',
    [contractKind.WRAPPED_NATIVE_TOKEN]: '',
  },
}
